<template>
  <v-card class="pa-5">
    <v-row v-if="!isCollapsed" align="center">
      <v-col class="col-8" align="left">
        <h3>Search Testers</h3>
      </v-col>
      <v-col class="col-4" align="right">
        <v-btn @click="collapse">
          <v-icon>mdi-less-than</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else align="center">
      <v-col align="right">
        <v-icon class="mr-3">mdi-trash-can</v-icon>
        <v-btn @click="expand">
          <v-icon>mdi-greater-than</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-form v-if="!isCollapsed"
      @submit.prevent="submit"
      ref="search"
      v-model="valid"
    >
      <v-text-field v-model="searchParams.discordID" label="Discord ID"></v-text-field>
      <v-text-field v-model="searchParams.firstName" label="First Name"></v-text-field>
      <v-text-field v-model="searchParams.lastName" label="Last Name"></v-text-field>
      <v-select v-model="searchParams.region" label="Region" :items="['North America', 'Europe', 'Asia']"/>
      <v-select v-model="searchParams.country" label="Country" :items="['Australia', 'Canada', 'China', 'Germany', 'Japan', 'Korea', 'United Kingdom', 'United States', 'Vietnam']"/>
      <v-select v-model="searchParams.ageRange" label="Age Range" :items="['18-25', '26-35', '36-55', '56+']"/>
      <v-select v-model="searchParams.gender" label="Gender" :items="['Male', 'Female', 'Other']"/>
      <v-select v-model="searchParams.platform" label="Platform" :items="platformsList"/>
      <v-text-field v-model="searchParams.downloadSpeed" label="Download Speed"></v-text-field>
      <v-text-field v-model="searchParams.uploadSpeed" label="Upload Speed"></v-text-field>
      <!-- Buttons -->
      <v-row class="justify-space-between">
        <v-btn
          @submit.prevent="submit"
          type="submit"
          :loading="loading"
          color="primaryButton"
          class="mt-3 white--text"
        >
          Search
        </v-btn>
        <v-btn
          :disabled="loading"
          color="primaryButton"
          class="mt-3 white--text"
          @click="resetFilters"
        >
          Reset Filters
        </v-btn>
      </v-row>
      <div v-if="error" class="red">{{ error }}</div>
    </v-form>
  </v-card>
</template>

<script>
  export default {
    name: 'Search',

    components: {
      //
    },

    data () {
      return {
        error: null,
        isCollapsed: false,
        loading: false,
        panel: [0],
        platformsList: [
          "Android Mobile", 
          "iPad Mobile", "iPhone Mobile",
          "PC", 
          "Playstation 4", "Playstation 4 Pro", "Playstation 5", 
          "Stadia", "Switch", 
          "Xbox One", "Xbox One S", "Xbox One X", 
          "Xbox Series S", "Xbox Series X"
        ],
        searchParams: {
          ageRange: '',
          country: '',
          discordID: '',
          downloadSpeed: '',
          firstName: '',
          gender: '',
          lastName: '',
          platform: '',
          region: '',
          uploadSpeed: ''
        },
        valid: false
      }
    },

    methods: {
      collapse () {
        this.isCollapsed = true
        this.$emit('collapseSearch')
      },

      expand () {
        this.isCollapsed = false
        this.$emit('expandSearch')
      },

      async submit () {
        this.error = ''
        this.$refs.search.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          this.$store.dispatch("searchTesters", { params: this.searchParams })
          .then((resp) => {
            this.loading = false

            // If already at search results, only update parent
            if (this.$router.currentRoute.path === '/tester-manage') {
              this.panel = []
              this.$emit('updateSearchResults', resp)
            // Otherwise, navigate to search results page, passing the results
            } else {
              this.$router.push({ name: 'TesterManage', params: { searchResults: resp } })
            }
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      },

      resetFilters () {
        this.searchParams = {
          ageRange: '',
          country: '',
          discordID: '',
          downloadSpeed: '',
          firstName: '',
          gender: '',
          lastName: '',
          platform: '',
          region: '',
          uploadSpeed: ''
        }
      }
    }
  }
</script>

<style scoped>

</style>
