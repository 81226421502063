<template>
  <v-row>
    <v-col class="d-none d-sm-flex" :class="isSearchCollapsed ? 'col-sm-1' : 'col-sm-2'">
      <Search
        v-on:updateSearchResults="onUpdateSearchResults"
        v-on:collapseSearch="isSearchCollapsed=true"
        v-on:expandSearch="isSearchCollapsed=false"
      />
    </v-col>
    <v-col :class="isSearchCollapsed ? 'col-sm-11' : 'col-sm-10'">
      <!-- Header -->
      <v-row justify="center">
        <div class="error--text mt-3">{{ error }}</div>
        <v-icon v-if="loading">mdi mdi-loading mdi-spin</v-icon>
      </v-row>
      <!-- Tabs -->
      <v-tabs v-model="tabs" class="pa-5" fixed-tabs>
        <v-tab>Groups</v-tab>
        <v-tab>Email</v-tab>
        <v-tab>Tracking</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <!-- Manage Tester Groups -->
        <v-tab-item>
          <!-- Data Tables -->
          <v-row align="center">
            <!-- Group 1 -->
            <v-col align-self="start" class="pr-1" :class="isGroup2Collapsed ? 'col-sm-12' : 'col-sm-6'">
              <!-- Group Controls -->
              <v-row>
                <!-- Group Select -->
                <v-select class="mr-4"
                  v-model="testerGroup1"
                  label="Tester Group"
                  :items="testerGroups"
                  :disabled="loading"
                  return-object
                ></v-select>
                <!-- Refresh -->
                <v-btn
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !testerGroup1"
                  @click="testerGroup1=Object.assign({}, testerGroup1)"
                >
                  <v-icon>mdi-rotate-right</v-icon>
                </v-btn>
                <!-- Duplicate -->
                <v-btn
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !testerGroup1 || testerGroup1.text === labelAllTesters || testerGroup1.text === labelSearchResults"
                  @click="duplicateTesterGroup(testerGroup1)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <!-- Save -->
                <v-btn
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !testerGroup1"
                  @click="updateTesterGroup(testerGroup1)"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <!-- Delete -->
                <v-btn
                  color="#6FBDDB"
                  class="darken-1 mr-3 mt-3 red white--text"
                  :disabled="loading || !testerGroup1 || testerGroup1.text === labelAllTesters || testerGroup1.text === labelSearchResults"
                  @click="testerGroupPendingDelete=testerGroup1; isShowingConfirmDeleteTesterGroupDialog=true"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                <!-- Expand/Collapse Group 2 -->
                <v-btn class="mt-3" @click="isGroup2Collapsed = !isGroup2Collapsed">
                  <v-icon v-if="isGroup2Collapsed">mdi-less-than</v-icon>
                  <v-icon v-else>mdi-greater-than</v-icon>
                </v-btn>
              </v-row>
              <!-- Tester Controls -->
              <v-row v-if="testerGroup1">
                <!-- Name -->
                <v-text-field
                  v-model="testerGroup1.text"
                  label="Group Name"
                  :disabled="testerGroup1.text === labelAllTesters || testerGroup1.text === labelSearchResults"
                  class="mr-4"
                >
                </v-text-field>
                <!-- Copy -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mr-3 mt-3"
                      v-on="on"
                      @click="copySelectedTesters('1')"
                      :disabled="selectedTesters1.length === 0 || !testerGroup2 || testerGroup2.text === labelAllTesters || testerGroup2.text === labelSearchResults"
                    >
                    <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Copy selected to other group
                </v-tooltip>
                <!-- Delete -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mt-3"
                      v-on="on"
                      @click="deleteSelectedTesters('1')"
                      :disabled="selectedTesters1.length === 0 || testerGroup1.text === labelAllTesters || testerGroup1.text === labelSearchResults"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  Delete selected testers
                </v-tooltip>
              </v-row>
              <!-- Data Table -->
              <v-data-table
                v-model="selectedTesters1"
                show-select
                :headers="headers1"
                :disabled="loading"
                :items="testers1"
                :items-per-page="10"
                class="elevation-1"
              >
                <template v-for="(header, i) in headers1" v-slot:[`item.${header.value}`]="{ item }">
                  <v-edit-dialog :key="i"
                    large
                    persistent
                    :return-value.sync="item[header.value]"
                    @save="save(item, item[header.value])"
                    @cancel="cancel"
                    @open="open"
                    @close="close(testers1.indexOf(item), item[header.value], header.value)"
                  >
                    <div>{{ item[header.value] }}</div>
                    <template v-slot:input>
                      <v-select v-if="header.value==='Platforms'"
                        v-model="item[header.value]"
                        :items="platformsList"
                        :disabled="loading"
                        return-object
                        multiple
                      ></v-select>
                      <v-textarea v-else
                        v-model="item[header.value]"
                        autofocus
                      ></v-textarea>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
              <!-- Tester Fields -->
              <v-row v-if="testerGroup1" justify="center" class="mt-5">
                <v-col :class="isGroup2Collapsed ? 'col-sm-4' : 'col-sm-8'">
                  <h2 class="text-center">Tester Fields</h2>
                  <v-row>
                    <!-- Field Name -->
                    <v-text-field v-model="newHeader1" label="New Field" class="mr-3"></v-text-field>
                    <!-- Save -->
                    <v-btn
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading"
                      @click="createHeader('1')"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <!-- Field Name -->
                    <v-select v-model="selectedHeader" :items="allHeaders1" label="Select Field" class="mr-3"></v-select>
                    <!-- Show/Hide -->
                    <v-btn v-if="!testerGroup1.hiddenHeaders || testerGroup1.hiddenHeaders.indexOf(selectedHeader) === -1"
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading || !selectedHeader"
                      @click="showHideHeader(testerGroup1, selectedHeader)"
                    >
                      <v-icon>mdi-eye-off</v-icon>
                    </v-btn>
                    <v-btn v-else
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading || !selectedHeader"
                      @click="showHideHeader(testerGroup1, selectedHeader)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <!-- Delete -->
                    <v-btn
                      color="#6FBDDB"
                      class="darken-1 mr-3 mt-3 red white--text"
                      :disabled="loading || !selectedHeader"
                      @click="headerPendingDelete=selectedHeader; grpPendingHeaderDelete=testerGroup1; isShowingConfirmDeleteHeaderDialog=true"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!-- Group 2 -->
            <v-col align-self="start" class="pl-1" :class="isGroup2Collapsed ? 'col-sm-0' : 'col-sm-6'">
              <!-- Group Controls -->
              <v-row>
                <!-- Group Select -->
                <v-select v-if="!isGroup2Collapsed" class="mr-4"
                  v-model="testerGroup2"
                  label="Tester Group"
                  :items="testerGroups"
                  :disabled="loading"
                  return-object
                ></v-select>
                <!-- Save -->
                <v-btn v-if="!isGroup2Collapsed" 
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !testerGroup2"
                  @click="updateTesterGroup(testerGroup2)"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <!-- Delete -->
                <v-btn v-if="!isGroup2Collapsed" 
                  color="#6FBDDB"
                  class="darken-1 mt-3 red white--text"
                  :disabled="loading || !testerGroup2 || testerGroup2.text === labelAllTesters || testerGroup2.text === labelSearchResults"
                  @click="testerGroupPendingDelete=testerGroup2; isShowingConfirmDeleteTesterGroupDialog=true"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
              <!-- Tester Controls -->
              <v-row v-if="testerGroup2 && !isGroup2Collapsed">
                <!-- Name -->
                <v-text-field
                  v-model="testerGroup2.text"
                  label="Group Name"
                  :disabled="testerGroup2.text === labelAllTesters || testerGroup2.text === labelSearchResults"
                  class="mr-4"
                >
                </v-text-field>
                <!-- Copy -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mr-3 mt-3"
                      v-on="on"
                      @click="copySelectedTesters('2')"
                      :disabled="selectedTesters2.length === 0 || !testerGroup1 || testerGroup1.text === labelAllTesters || testerGroup1.text === labelSearchResults"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Copy selected to other group
                </v-tooltip>
                <!-- Delete -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn class="mt-3"
                      v-on="on"
                      @click="deleteSelectedTesters('2')"
                      :disabled="selectedTesters2.length === 0 || testerGroup2.text === labelAllTesters || testerGroup2.text === labelSearchResults"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  Delete selected testers
                </v-tooltip>
              </v-row>
              <!-- Data Table -->
              <v-data-table v-if="!isGroup2Collapsed"
                v-model="selectedTesters2"
                show-select
                :headers="headers2"
                :items="testers2"
                :items-per-page="10"
                class="elevation-1"
              ></v-data-table>
              <!-- Tester Fields -->
              <v-row v-if="testerGroup2" justify="center" class="mt-5">
                <v-col cols="6">
                  <h2 class="text-center">Tester Fields</h2>
                  <v-row>
                    <!-- Field Name -->
                    <v-text-field v-model="newHeader2" label="New Field" class="mr-3"></v-text-field>
                    <!-- Save -->
                    <v-btn
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading"
                      @click="createHeader('2')"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </v-row>
                  <v-row>
                    <!-- Field Name -->
                    <v-select v-model="selectedHeader" :items="allHeaders2" label="Select Field" class="mr-3"></v-select>
                    <!-- Show/Hide -->
                    <v-btn v-if="!testerGroup2.hiddenHeaders || testerGroup2.hiddenHeaders.indexOf(selectedHeader) === -1"
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading || !selectedHeader"
                      @click="showHideHeader(testerGroup2, selectedHeader)"
                    >
                      <v-icon>mdi-eye-off</v-icon>
                    </v-btn>
                    <v-btn v-else
                      color="#6FBDDB"
                      class="mr-3 mt-3 white--text"
                      :disabled="loading || !selectedHeader"
                      @click="showHideHeader(testerGroup2, selectedHeader)"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <!-- Delete -->
                    <v-btn
                      color="#6FBDDB"
                      class="darken-1 mr-3 mt-3 red white--text"
                      :disabled="loading || !selectedHeader"
                      @click="headerPendingDelete=selectedHeader; grpPendingHeaderDelete=testerGroup2; isShowingConfirmDeleteHeaderDialog=true"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Email Testers -->
        <v-tab-item>
          <v-row justify="center">
            <v-col cols="6" class="pr-1">
              <!-- Controls -->
              <v-row>
                <v-select class="mr-4"
                  v-model="selectedEmailTemplate"
                  label="Email Template"
                  :items="emailTemplates"
                  :disabled="loading"
                  return-object
                ></v-select>
                <v-btn
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !selectedEmailTemplate"
                  @click="createEmailTemplate"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  color="#6FBDDB"
                  class="mr-3 mt-3 white--text"
                  :disabled="loading || !selectedEmailTemplate"
                  @click="updateEmailTemplate"
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  color="#6FBDDB"
                  class="darken-1 mt-3 red white--text"
                  :disabled="loading || !selectedEmailTemplate"
                  @click="isShowingConfirmDeleteEmailTemplateDialog=true"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </v-row>
              <!-- Variables -->
              <div v-if="selectedEmailTemplate">
                <v-text-field v-model="selectedEmailTemplate.text" label="Template Name"></v-text-field>
                <v-text-field v-model="selectedEmailTemplate.subject" label="Email Subject"></v-text-field>
                <v-textarea label="Header" v-model="tHeader" placeholder="Type replacement text here..."></v-textarea>
                <v-textarea label="Body" v-model="tBody" placeholder="Type replacement text here..."></v-textarea>
                <v-text-field v-model="tDiscordInviteURL" label="Discord Invite URL"></v-text-field>
                <v-text-field v-model="tCognitoFormURL" label="Cognito Form URL"></v-text-field>
                <v-text-field v-model="tInstructionsURL" label="Test Instructions URL"></v-text-field>
              </div>
            </v-col>
            <v-col cols="6" align="center" class="pl-1">
              <!-- Email -->
              <v-btn
                @click="isShowingConfirmEmailDialog=true; isEmailSent=false"
                color="#6FBDDB"
                class="mb-3 mt-3 white--text"
                :disabled="!selectedEmailTemplate || !testerGroup1"
              >
                <v-icon>mdi-email</v-icon>
                Email Testers
              </v-btn>
              <!-- Preview -->
              <div v-if="selectedEmailTemplate" v-html="selectedEmailTemplate.html"></div>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tracking -->
        <v-tab-item>
          <v-card class="pa-5">
            <v-card-title>DAQA Project Hera 7 - Test and Login Instructions</v-card-title>
            <v-card class="darken-2 grey mt-5 pa-5 text-center">
              <v-row>
                <v-col cols="4" class="pr-2">
                  Audience: 9999
                </v-col>
                <v-col cols="4" class="pl-2 pr-2">
                  Subject: Newest Email Campaign
                </v-col>
                <v-col cols="4" class="pl-2">
                  Delivered: 9/9/9999
                </v-col>
              </v-row>
            </v-card>
            <v-row class="mt-5">
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Opened</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Clicked</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Bounced</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Unbsubscribed</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="pa-5">
              <v-col cols="1"></v-col>
              <v-col cols="4" class="pr-3">
                <v-row align="center">
                  <v-text-field label="Successful Deliveries" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Total Opens" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Last Opened" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Forwarded" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4" class="pr-3">
                <v-row align="center">
                  <v-text-field label="Clicks per Unique Opens" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Total Clicks" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Last Clicked" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
                <v-row align="center">
                  <v-text-field label="Abuse Reports" disabled></v-text-field>
                  <div>9999</div>
                </v-row>
              </v-col>
              <v-col cols="1"></v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Orders</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Average Order Revenue</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
              <v-col cols="3" class="pr-2">
                <v-card class="darken-3 grey pa-5 text-center">
                  <div><h2>Total Revenue</h2></div>
                  <div>9999</div>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="4"></v-col>
              <v-col cols="4">
                <v-expansion-panels class="pa-5">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="white--text" style="background-color: #2B3C4F;">
                      <strong>Activity Feed</strong>
                      <template v-slot:actions>
                        <v-icon color="white">$expand</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-5">
                      <v-row><h3>June 6th</h3></v-row>
                      <v-row align="center"><v-icon class="mr-3">mdi-eye</v-icon>Opened the email Hera 6 Test Instructions</v-row>
                      <v-row><i>12 PM PST</i></v-row>
                      <v-row class="mt-5"><h3>May 25th</h3></v-row>
                      <v-row align="center"><v-icon class="mr-3">mdi-eye</v-icon>Opened the email Hera 6 Test Instructions</v-row>
                      <v-row><i>12 PM PST</i></v-row>
                      <v-row align="center"><v-icon class="mr-3">mdi-email</v-icon>Was sent the email Hera 6 Test Instructions</v-row>
                      <v-row><i>12 PM PST</i></v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="4"></v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <!-- Confirm Send Email Dialog -->
      <v-dialog v-model="isShowingConfirmEmailDialog" width="500">
        <v-card>
          <v-card-title>Send Email</v-card-title>
          <v-card-text v-if="!isEmailSent">Are you sure you want to send an email to every tester in this group? <strong>This action cannot be undone.</strong></v-card-text>
          <v-card-text v-if="!isEmailSent">
            <div v-for="i in 5" :key="i">
              <div v-if="testers1.length > i - 1">{{ testers1[i - 1]["Email"] }}</div>
            </div>
            <div v-if="testers1.length > 5">+{{ testers1.length - 5 }} more</div>
          </v-card-text>
          <v-card-text v-else>
            Email(s) sent successfully!
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!isEmailSent"
              @click="submitEmail"
              :loading="loading"
              color="red white--text"
            >
              <v-icon class="mr-2">mdi-email</v-icon>
              Confirm
            </v-btn>
            <v-btn v-if="!isEmailSent"
              :disabled="loading"
              @click="isShowingConfirmEmailDialog=false"
            >
              Cancel
            </v-btn>
            <v-btn v-else
              :disabled="loading"
              @click="isShowingConfirmEmailDialog=false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Header Dialog -->
      <v-dialog v-model="isShowingConfirmDeleteHeaderDialog" v-if="headerPendingDelete && headerPendingDelete != ''" width="500">
        <v-card>
          <v-card-title>Delete Tester Field</v-card-title>
          <v-card-text>Are you sure you want to delete the <u>{{ headerPendingDelete }}</u> header <strong>and all associated values from every tester in this group?</strong></v-card-text>
          <v-card-text class="text-center"><strong>This action will update the database and cannot be undone.</strong></v-card-text>
          <v-card-actions>
            <v-btn
              @click="deleteHeader"
              :loading="loading"
              :disabled="loading"
              color="red white--text"
            >
              <v-icon class="mr-2">mdi-trash-can</v-icon>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingConfirmDeleteHeaderDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Publisher Dialog -->
      <v-dialog v-model="isShowingConfirmDeleteEmailTemplateDialog" width="500">
        <v-card>
          <v-card-title>Delete Email Template</v-card-title>
          <v-card-text>Are you sure you want to delete this email template?</v-card-text>
          <v-card-actions>
            <v-btn
              @click="deleteEmailTemplate"
              :loading="loading"
              color="red white--text"
            >
              <v-icon class="mr-2">mdi-trash-can</v-icon>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingConfirmDeleteEmailTemplateDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Confirm Delete Tester Group Dialog -->
      <v-dialog v-model="isShowingConfirmDeleteTesterGroupDialog" width="500">
        <v-card>
          <v-card-title>Delete Tester Group</v-card-title>
          <v-card-text>Are you sure you want to delete this tester group?</v-card-text>
          <v-card-actions>
            <v-btn
              @click="deleteTesterGroup"
              :loading="loading"
              color="red white--text"
            >
              <v-icon class="mr-2">mdi-trash-can</v-icon>
              Delete
            </v-btn>
            <v-btn
              :disabled="loading"
              @click="isShowingConfirmDeleteTesterGroupDialog=false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import Search from "@/components/Search"

  export default {
    name: 'TesterManage',

    components: {
      Search
    },

    data () {
      return {
        allHeaders1: [],
        allHeaders2: [],
        emailTemplates: [],
        error: '',
        grpPendingHeaderDelete: null,
        headerPendingDelete: '',
        headers1: [],
        headers2: [],
        isGroup2Collapsed: true,
        isEmailSent: false,
        isSaving: false,
        isSearchCollapsed: false,
        isShowingConfirmDeleteEmailTemplateDialog: false,
        isShowingConfirmDeleteHeaderDialog: false,
        isShowingConfirmDeleteTesterGroupDialog: false,
        isShowingConfirmEmailDialog: false,
        labelAllTesters: '[ All Testers ]',
        labelSearchResults: '[ Search Results ]',
        loading: false,
        newHeader1: '',
        newHeader2: '',
        panel: 0,
        platformsList: [
          "Android Mobile", 
          "iPad Mobile", "iPhone Mobile",
          "PC", 
          "Playstation 4", "Playstation 4 Pro", "Playstation 5", 
          "Stadia", "Switch", 
          "Xbox One", "Xbox One S", "Xbox One X", 
          "Xbox Series S", "Xbox Series X"
        ],
        searchResults: [],
        selectedHeader: '',
        selectedTesters1: [],
        selectedTesters2: [],
        selectedEmailTemplate: null,
        tabs: '',
        tBody: '',
        tCognitoFormURL: '',
        tDiscordInviteURL: '',
        testerGroup1: null,
        testerGroup2: null,
        testerGroupPendingDelete: null,
        testerGroups: [],
        testers1: [],
        testers2: [],
        tHeader: '',
        tInstructionsURL: '',
        updateSearchResults: null
      }
    },

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    watch: {
      selectedEmailTemplate () {
        this.updateTemplatePreview()
        this.tCognitoFormURL = this.tDiscordInviteURL = this.tInstructionsURL = ''
      },

      tBody () {
        this.updateTemplateSource()
      },

      tDiscordInviteURL () {
        if (!this.tBody || this.tBody.length === 0) return

        var urlStartTag = `<span id="tDiscordInviteURL1"></span><a href='`
        var urlStartIndex = this.tBody.indexOf(urlStartTag)
        var urlEndIndex = this.tBody.indexOf(`' target="_blank"><span id="tDiscordInviteURL2"></span>`)
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          var fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tDiscordInviteURL)
        }

        urlStartTag = '<span id="tDiscordInviteURL3"></span>'
        urlStartIndex = this.tBody.indexOf(urlStartTag)
        urlEndIndex = this.tBody.indexOf('<span id="tDiscordInviteURL4"></span>')
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tDiscordInviteURL)
        }
      },

      tCognitoFormURL () {
        if (!this.tBody || this.tBody.length === 0) return

        var urlStartTag = `<span id="tCognitoFormURL1"></span><a href='`
        var urlStartIndex = this.tBody.indexOf(urlStartTag)
        var urlEndIndex = this.tBody.indexOf(`' target="_blank"><span id="tCognitoFormURL2"></span>`)
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          var fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tCognitoFormURL)
        }

        urlStartTag = '<span id="tCognitoFormURL3"></span>'
        urlStartIndex = this.tBody.indexOf(urlStartTag)
        urlEndIndex = this.tBody.indexOf('<span id="tCognitoFormURL4"></span>')
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tCognitoFormURL)
        }
      },

      tInstructionsURL () {
        if (!this.tBody || this.tBody.length === 0) return

        var urlStartTag = `<span id="tInstructionsURL1"></span><a href='`
        var urlStartIndex = this.tBody.indexOf(urlStartTag)
        var urlEndIndex = this.tBody.indexOf(`' target="_blank"><span id="tInstructionsURL2"></span>`)
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          var fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tInstructionsURL)
        }

        urlStartTag = '<span id="tInstructionsURL3"></span>'
        urlStartIndex = this.tBody.indexOf(urlStartTag)
        urlEndIndex = this.tBody.indexOf('<span id="tInstructionsURL4"></span>')
        if (urlStartIndex > -1 && urlEndIndex > -1) {
          fullURL = this.tBody.substring(urlStartIndex + urlStartTag.length, urlEndIndex)
          this.tBody = this.tBody.replace(urlStartTag + fullURL, urlStartTag + this.tInstructionsURL)
        }
      },

      async testerGroup1 () {
        this.selectedTesters1 = []
        if (this.testerGroup1) {
          await this.getTesters(this.testerGroup1)
          this.buildHeaders(this.testerGroup1)
        } else {
          this.testers1 = []
          this.headers1 = []
        }
      },

      async testerGroup2 () {
        this.selectedTesters2 = []
        if (this.testerGroup2) {
          await this.getTesters(this.testerGroup2)
          this.buildHeaders(this.testerGroup2)
        } else {
          this.testers2 = []
          this.headers2 = []
        }
      },

      tHeader () {
        const headerStartTag = '<span id="tHead1"></span>'
        const headerStartIndex = this.selectedEmailTemplate.html.indexOf(headerStartTag)
        const headerEndIndex = this.selectedEmailTemplate.html.indexOf('<span id="tHead2"></span>')
        if (headerStartIndex > -1 && headerEndIndex > -1) {
          const fullHeader = this.selectedEmailTemplate.html.substring(headerStartIndex + headerStartTag.length, headerEndIndex)
          this.selectedEmailTemplate.html = this.selectedEmailTemplate.html.replace(headerStartTag + fullHeader, headerStartTag + this.tHeader)
        }
      }
    },

    async created () {
      await this.getTesterGroups()
      this.getEmailTemplates()
      if (this.searchResults) this.onUpdateSearchResults(this.searchResults)
    },

    methods: {
      save (item, value) {
        // If the new value has line breaks in it, split it before saving
        if (value.indexOf("\n") > -1) {
          this.isSaving = true // wait for dialog to close before continuing, otherwise data-table will overwrite our values
        // Otherwise, just save the item directly
        } else {
          this.updateTester(item)
        }
      },

      cancel () {
        //console.log('cancelled')
      },

      open () {
        this.isSaving = false
      },

      close (index, value, propName) {
        if (this.isSaving) {
          this.$nextTick(() => {
            // Replace values sequentially
            const values = value.split("\n")
            for (var i = index; i < this.testers1.length; i++) {
              this.testers1[i][propName] = values.shift()
              this.updateTester(this.testers1[i])
              if (values.length === 0) break
            }
          })
        }
      },

      buildHeaders (grp) {
        var headers
        var allHeaders
        var testers
        if (grp === this.testerGroup1) {
          headers = this.headers1 = []
          allHeaders = this.allHeaders1 = []
          testers = this.testers1
        } else {
          headers = this.headers2 = []
          allHeaders = this.allHeaders2 = []
          testers = this.testers2
        }

        for (var i = 0; i < testers.length; i++) {
          keyLoop: for (const key in testers[i]) {
            for (var k = 0; k < allHeaders.length; k++) {
              if (allHeaders[k].text == key) continue keyLoop
            }
            if (key != 'id' && Object.hasOwnProperty.call(testers[i], key)) {
              allHeaders.push({
                text: key,
                value: key
              })
              if (grp.hiddenHeaders && grp.hiddenHeaders.indexOf(key) > -1) continue
              headers.push({
                text: key,
                value: key
              })
            }
          }
        }

        // Put headers in a certain order
        const headerOrder = ['DiscordID', 'FName', 'LName']
        for (i = headerOrder.length - 1; i >= 0; i--) {
          for (var j = 0; j < headers.length; j++) {
            if (headers[j].value === headerOrder[i]) {
              const header = headers[j]
              headers.splice(j, 1)
              headers.unshift(header)
              break
            }
          }
        }

        // Put All Headers in alphabetical order
        allHeaders.sort(function(a, b) {
            var textA = a.text.toUpperCase();
            var textB = b.text.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      },

      copySelectedTesters (fromGrp) {
        const toTesters = fromGrp === "1" ? this.testerGroup2 : this.testerGroup1
        const selectedTesters = fromGrp === "1" ? this.selectedTesters1 : this.selectedTesters2
        for (var i = 0; i < selectedTesters.length; i++) {
          if (toTesters.testerIDs.indexOf(selectedTesters[i].id) === -1) { // only add to other list if not already there
            toTesters.testerIDs.push(selectedTesters[i].id)
          }
        }

        this.testerGroup1 = Object.assign({}, this.testerGroup1) // components relying on this prop will not update otherwise
        this.testerGroup2 = Object.assign({}, this.testerGroup2)
      },

      createEmailTemplate () {
        var newTemplate = Object.assign({}, this.selectedEmailTemplate)
        newTemplate.text += " (Copy)"
        this.loading = true
        this.$store.dispatch('createEmailTemplate', newTemplate)
        .then(() => {
          this.loading = false
          this.getEmailTemplates()
          this.selectedEmailTemplate = newTemplate
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      async createHeader (grp) {
        const headerText = grp === "1" ? this.newHeader1 : this.newHeader2
        const testers = grp === "1" ? this.testers1 : this.testers2
        var header = {
          text: headerText,
          value: headerText
        }
        for (var i = 0; i < testers.length; i++) {
          testers[i][header.text] = testers[i][header.text] || ''
          await this.updateTester(testers[i])
        }
        this.newHeader1 = this.newHeader2 = ''
        if (grp === "1") this.testerGroup1 = Object.assign({}, this.testerGroup1) // force refresh
        if (grp === "2") this.testerGroup2 = Object.assign({}, this.testerGroup2)
      },

      deleteEmailTemplate () {
        this.loading = true
        this.$store.dispatch('deleteEmailTemplate', this.selectedEmailTemplate.id)
        .then(() => {
          this.loading = false
          this.isShowingConfirmDeleteEmailTemplateDialog = false
          this.getEmailTemplates()
          this.selectedEmailTemplate = null
        })
        .catch((err) => {
          this.loading = false
          this.isShowingConfirmDeleteEmailTemplateDialog = false
          this.error = err
        })
      },

      async deleteHeader () {
        this.loading = true
        var testers = this.grpPendingHeaderDelete === this.testerGroup1 ? this.testers1 : this.testers2
        for (var i = 0; i < testers.length; i++) {
          await this.deleteTesterField(testers[i], this.headerPendingDelete)
        }
        if (this.grpPendingHeaderDelete.hiddenHeaders && this.grpPendingHeaderDelete.hiddenHeaders.indexOf(this.headerPendingDelete) > -1) {
          this.grpPendingHeaderDelete.hiddenHeaders.splice(this.grpPendingHeaderDelete.hiddenHeaders.indexOf(this.headerPendingDelete), 1)
          this.updateTesterGroup(this.grpPendingHeaderDelete)
        }
        else
        {
          if (this.grpPendingHeaderDelete.testers === this.testerGroup1) this.testerGroup1 = Object.assign({}, this.testerGroup1) // force refresh
          if (this.grpPendingHeaderDelete.testers === this.testerGroup2) this.testerGroup2 = Object.assign({}, this.testerGroup2)
        }
        this.grpPendingHeaderDelete = null
        this.headerPendingDelete = ''
      },

      deleteSelectedTesters (fromGrp) {
        const grp = fromGrp === "1" ? this.testerGroup1 : this.testerGroup2
        const grpTesters = fromGrp === "1" ? this.testers1 : this.testers2
        const selectedTesters = fromGrp === "1" ? this.selectedTesters1 : this.selectedTesters2
        for (var i = grpTesters.length - 1; i >= 0; i--) {
          for (var j = selectedTesters.length - 1; j >= 0; j--) {
            if (selectedTesters[j].id === grpTesters[i].id) {
              grpTesters.splice(i, 1)
              grp.testerIDs.splice(grp.testerIDs.indexOf(selectedTesters[j].id), 1)
              this.updateTesterGroup(grp)
              break
            }
          }
        }

        if (fromGrp === "1") this.selectedTesters1 = []
        else this.selectedTesters2 = []

        this.$forceUpdate()
      },

      deleteTesterGroup () {
        if (!this.testerGroupPendingDelete) return
        this.loading = true
        this.$store.dispatch('deleteTesterGroup', this.testerGroupPendingDelete.id)
        .then(() => {
          this.loading = false
          this.isShowingConfirmDeleteTesterGroupDialog = false
          if (this.testerGroupPendingDelete === this.testerGroup1) {
            this.testerGroup1 = null
            this.testers1 = []
          }
          else {
            this.testerGroup2 = null
            this.testers2 = []
          }
          this.getTesterGroups()
          this.testerGroupPendingDelete = null
        })
        .catch((err) => {
          this.loading = false
          this.isShowingConfirmDeleteTesterGroupDialog = false
          this.error = err
        })
      },

      async deleteTesterField (tester, field) {
        this.loading = true
        await this.$store.dispatch('deleteTesterField', { tester, field })
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      duplicateTesterGroup(grp) {
        var newGrp = Object.assign({}, grp)
        delete newGrp.formID
        newGrp.text += " (Copy)"
        this.loading = true
        this.$store.dispatch('createTesterGroup', newGrp)
        .then(() => {
          this.getTesterGroups()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      getEmailTemplates () {
        this.loading = true
        this.$store.dispatch('getEmailTemplates')
        .then((resp) => {
          this.loading = false
          this.emailTemplates = resp
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      getTesterGroups () {
        this.loading = true
        this.$store.dispatch('getTesterGroups')
        .then((resp) => {
          this.loading = false
          this.testerGroups = resp
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      async getTesters (testerGroup) {
        // All Testers
        if (testerGroup.text === this.labelAllTesters) {
          this.loading = true
          await this.$store.dispatch("searchTesters", { params: undefined })
          .then((resp) => {
            this.loading = false
            if (testerGroup === this.testerGroup1) this.testers1 = resp
            else this.testers2 = resp
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        // Search Results
        } else if (testerGroup.text === this.labelSearchResults) {
          if (testerGroup === this.testerGroup1) this.testers1 = this.searchResults || []
          else this.testers2 = this.searchResults || []
        // Specified Testers
        } else {
          this.loading = true
          await this.$store.dispatch("getTesters", testerGroup.testerIDs)
          .then((resp) => {
            this.loading = false
            if (testerGroup === this.testerGroup1) this.testers1 = resp
            else this.testers2 = resp
          })
          .catch((err) => {
            this.loading = false
            this.error = err
          })
        }
      },

      onUpdateSearchResults (results) {
        this.searchResults = results

        // Switch to search results tester group
        for (var i = 0; i < this.testerGroups.length; i++) {
          if (this.testerGroups[i].text === this.labelSearchResults) {
            this.testerGroup1 = Object.assign({}, this.testerGroups[i]) // components relying on this prop will not update otherwise
            break
          }
        }
      },

      showHideHeader (grp, headerKey) {
        console.log(headerKey)
        if (!grp.hiddenHeaders) {
          grp.hiddenHeaders = [headerKey]
        } else if (grp.hiddenHeaders.indexOf(headerKey) > -1) {
          grp.hiddenHeaders.splice(grp.hiddenHeaders.indexOf(headerKey), 1)
        } else {
          grp.hiddenHeaders.push(headerKey)
        }

        this.updateTesterGroup(grp)
        this.buildHeaders(grp)
      },
      
      // Send data off to nodemailer function
      submitEmail () {
        this.error = ''
        this.loading = true
        var posts = []
        for (var i = 0; i < this.testers1.length; i++) {
          this.updateTemplatePreview(this.testers1[i])
          this.updateTemplateSource()
          posts.push(axios.post('https://us-central1-dev-qabbb.cloudfunctions.net/api/email', {
            emailAddress: this.testers1[i].Email,
            subject: this.selectedEmailTemplate.subject,
            html: this.selectedEmailTemplate.html
          }))
          
          this.$gtag.event('tester_email_sent', {
            'email_subject': this.selectedEmailTemplate.subject,
            'email_sent_to': this.testers1[i].Email
          });
        }

        axios.all(posts)
        .then(axios.spread((data1, data2) => {
          console.log(data1, data2)
          this.loading = false
          this.isEmailSent = true
        }))
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      updateEmailTemplate () {
        this.loading = true
        this.$store.dispatch('updateEmailTemplate', this.selectedEmailTemplate)
        .then(() => {
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      updateTemplatePreview (tester = null) {
        if (this.selectedEmailTemplate) {
          // Header
          const headerStartTag = '<span id="tHead1"></span>'
          const headerStartIndex = this.selectedEmailTemplate.html.indexOf(headerStartTag)
          const headerEndIndex = this.selectedEmailTemplate.html.indexOf('<span id="tHead2"></span>')
          if (headerStartIndex > -1 && headerEndIndex > -1) {
            this.tHeader = this.selectedEmailTemplate.html.substring(headerStartIndex + headerStartTag.length, headerEndIndex)
          }

          // Body
          const bodyStartTag = '<span id="tBody1"></span>'
          const bodyStartIndex = this.selectedEmailTemplate.html.indexOf(bodyStartTag)
          const bodyEndIndex = this.selectedEmailTemplate.html.indexOf('<span id="tBody2"></span>')
          if (bodyStartIndex > -1 && bodyEndIndex > -1) {
            this.tBody = this.selectedEmailTemplate.html.substring(bodyStartIndex + bodyStartTag.length, bodyEndIndex)
          }

          if (!tester && this.testers1.length > 0) tester = this.testers1[0]

          // Tester First Name
          if (tester && this.tBody.length > 0) {
            const fName = tester["FName"]
            const fNameStartTag = '<span id="tFName1"></span>'
            const fNameStartIndex = this.tBody.indexOf(fNameStartTag)
            const fNameEndIndex = this.tBody.indexOf('<span id="tFName2"></span>')
            if (fNameStartIndex > -1 && fNameEndIndex > -1) {
              const fullFName = this.tBody.substring(fNameStartIndex + fNameStartTag.length, fNameEndIndex)
              this.tBody = this.tBody.replace(fNameStartTag + fullFName, fNameStartTag + fName)
            }
          }

          // Tester Platform
          if (tester && this.tBody.length > 0) {
            const plat = tester["TestingPlatform"]
            const platStartTag = '<span id="tTestingPlatform1"></span>'
            const platStartIndex = this.tBody.indexOf(platStartTag)
            const platEndIndex = this.tBody.indexOf('<span id="tTestingPlatform2"></span>')
            if (platStartIndex > -1 && platEndIndex > -1) {
              const fullPlat = this.tBody.substring(platStartIndex + platStartTag.length, platEndIndex)
              this.tBody = this.tBody.replace(platStartTag + fullPlat, platStartTag + plat)
            }
          }

          // Tester Game Code
          if (tester && this.tBody.length > 0) {
            const code = tester["GameCode"]
            const codeStartTag = '<span id="tCode1"></span>'
            const codeStartIndex = this.tBody.indexOf(codeStartTag)
            const codeEndIndex = this.tBody.indexOf('<span id="tCode2"></span>')
            if (codeStartIndex > -1 && codeEndIndex > -1) {
              const fullCode = this.tBody.substring(codeStartIndex + codeStartTag.length, codeEndIndex)
              this.tBody = this.tBody.replace(codeStartTag + fullCode, codeStartTag + code)
            }
          }
        }
      },

      updateTemplateSource () {
        // Body
        const bodyStartTag = '<span id="tBody1"></span>'
        const bodyStartIndex = this.selectedEmailTemplate.html.indexOf(bodyStartTag)
        const bodyEndIndex = this.selectedEmailTemplate.html.indexOf('<span id="tBody2"></span>')
        if (bodyStartIndex > -1 && bodyEndIndex > -1) {
          const fullBody = this.selectedEmailTemplate.html.substring(bodyStartIndex + bodyStartTag.length, bodyEndIndex)
          this.selectedEmailTemplate.html = this.selectedEmailTemplate.html.replace(bodyStartTag + fullBody, bodyStartTag + this.tBody)
        }

        // Current Year
        const yearStartTag = '<span id="tYear1"></span>'
        const yearStartIndex = this.selectedEmailTemplate.html.indexOf(yearStartTag)
        const yearEndIndex = this.selectedEmailTemplate.html.indexOf('<span id="tYear2"></span>')
        if (yearStartIndex > -1 && yearEndIndex > -1) {
          const year = this.selectedEmailTemplate.html.substring(yearStartIndex + yearStartTag.length, yearEndIndex)
          this.selectedEmailTemplate.html = this.selectedEmailTemplate.html.replace(yearStartTag + year, yearStartTag + new Date().getFullYear().toString())
        }
      },

      updateTesterGroup (group) {
        this.loading = true
        this.$store.dispatch('updateTesterGroup', group)
        .then(() => {
          this.loading = false
          this.getTesterGroups()
        })
        .catch((err) => {
          this.loading = false
          this.error = err
        })
      },

      async updateTester (tester) {
        this.loading = true
        await this.$store.dispatch('updateTester', tester)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>

</style>